<template>
  <div class="navigation_items">
      <router-link
        v-for="item, index in navList"
        :key="index"
        :to="item.to"
        tag="div"
        class="nav_item"
      >
        <div class="nav_item__icon" :style="{mask: `url(${item.icon}) no-repeat center / contain`, '-webkit-mask': `url(${item.icon}) no-repeat center / contain`, ...item.style}">
        </div>
        {{item.text}}
      </router-link>
  </div>
</template>

<script>
export default {
    data: () => ({
        navList: [
            {text: 'Проживание', to: '/booking/residence', icon: require('@/assets/images/booking/residence.svg'), style: {width: '16px', height: '11px'}},
            {text: 'Ж/д билеты', to: '/booking/trains', icon: require('@/assets/images/booking/trains.svg'),style: {width: '11px', height: '16px'}},
            {text: 'Автобус', to: '/booking/buses', icon: require('@/assets/images/booking/bus.svg'),style: {width: '16px', height: '16px'}},
            {text: 'Туры выходного дня', to: '/booking/tours', icon: require('@/assets/images/booking/excursion.svg'),style: {width: '16px', height: '15px'}},
            {text: 'Трансфер', to: '/booking/transfer', icon: require('@/assets/images/booking/transfer.svg'),style: {width: '16px', height: '16px'}},
        ]
    })
}
</script>

<style lang="scss" scoped>
.navigation_items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 2px;
    align-items: center;
}
.nav_item {
    background: #9CAEBC;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 14px 14px 0px 0px;
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    &:first-of-type {
        border-radius: 4px 14px 0px 0px;
    }
    &:last-of-type {
        border-radius: 14px 4px 0px 0px;
    }
    &__icon {
        background-color: #fff;
        margin-right: 6px;
    }
}
.router-link-exact-active,
.router-link-active {
    background: #52B1BA;
}
</style>