<template>
    <div>
        <booking-navigation/>
        <router-view/>
    </div>
</template>

<script>
import bookingNavigation from '@/components/booking/bookingNavigation.vue'
export default {
    components: { 
        bookingNavigation
    },

}
</script>

<style lang="scss" scoped>

</style>